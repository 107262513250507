import '@fortawesome/fontawesome-free/css/all.css';
import 'typeface-montserrat'
import '../fonts/nucleo/css/nucleo.css'
import  'bootstrap/scss/bootstrap.scss';
import '../css/global-bootstrap.scss';
//import '../css/global-argon.scss';
import '../css/creative_tim/now-ui-kit/now-ui-kit.scss'
import '../css/app.css';


import 'jquery';
import 'popper.js';
import 'bootstrap';
//import  './argon';
import './creative_tim/now_ui_kit/now-ui-kit'
//import './map'

import '@fortawesome/fontawesome-free/css/all.css';
import 'typeface-montserrat'
import '../fonts/nucleo/css/nucleo.css'
import  'bootstrap/scss/bootstrap.scss';
import '../css/global-bootstrap.scss';
//import '../css/global-argon.scss';
import '../css/creative_tim/now-ui-kit/now-ui-kit.scss'
import '../css/app.css';


import 'jquery';
import 'popper.js';
import 'bootstrap';
//import  './argon';
import './creative_tim/now_ui_kit/now-ui-kit'
import './map'

/**
 * Must a tag, while scrollspy working with it
 */
$(document).ready(function () {
    if (window.location.hash) {
        $('.scrollspy a[href="/' + window.location.hash + '"]').addClass('active');
    }
    else if (window.location.pathname === '/') {
        $('.scrollspy a[href="/#home"]').addClass('active');
    }

    $(".scrollspy a[href*='#']").on('click', function(e) {
        console.log('bbb');
        let hash = this.hash;
        if ($(hash).length) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 900, function () {
                window.location.hash = hash;
            });
        }
    });
});

$(function($) {
    if (window.location.pathname === '/') {
        $('.scrollspy').find('a[href]').each(function (i, a) {
            let $a = $(a);
            let href = $a.attr('href');

            if (href.indexOf('/#') === 0) {
                href = href.replace('/', '');
                $a.attr('href', href);
            }

            $('[data-spy="scroll"]').each(function (i, spy) {
                $(spy).scrollspy('refresh');
            });
        });
    }
});