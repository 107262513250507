function map() {
    var center   = new google.maps.LatLng(52.264279, 10.528405);
    var mapProp= {
        center: center,
        zoom: 16,
        gestureHandling: 'cooperative',
        disableDefaultUI: 'true'
    };
    var map = new google.maps.Map(document.getElementById("googleMap"), mapProp);

    var marker = new google.maps.Marker({
        position: center,
        animation: google.maps.Animation.BOUNCE
    });
    marker.setMap(map);

    var infoWindow = new google.maps.InfoWindow({
        content: 'Klicken zum Zoomen'
    });
    infoWindow.open(map, marker);

    google.maps.event.addListener(marker, 'click', function() {
        var pos = map.getZoom();
        map.setZoom(19);
        map.setCenter(marker.getPosition());
        window.setTimeout(function() { map.setZoom(pos); }, 5000);
    });
}